import { translate, TxKeyPath } from "@/i18n";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    InputHTMLAttributes,
} from "react";
import SwitchInput from "./SwitchInput";
import { classNames } from "@/Utils/theme";

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
    isFocused?: boolean;
    label?: string;
    labelTx?: TxKeyPath;
    descriptionTx?: TxKeyPath;
    inputClassName?: string;
    fullWidth?: boolean;
    whiteBackground?: boolean;
};

export default forwardRef(function TextInput(
    {
        type = "text",
        className = "",
        inputClassName = "",
        isFocused = false,
        label,
        labelTx,
        descriptionTx,
        fullWidth = true,
        whiteBackground,
        ...props
    }: TextInputProps,
    ref
) {
    const localRef = useRef<HTMLInputElement>(null);

    const labelContent = labelTx ? translate(labelTx) : label;
    const descriptionContent = descriptionTx
        ? translate(descriptionTx)
        : undefined;

    const inputClassNameExtra = fullWidth ? " w-full" : "";

    const inputBackground = whiteBackground ? " shadow-md" : " shadow-sm";

    useImperativeHandle(ref, () => ({
        focus: () => localRef.current?.focus(),
    }));

    useEffect(() => {
        if (isFocused) {
            localRef.current?.focus();
        }
    }, []);

    return (
        <div className={"relative" + className}>
            {type !== "select" &&
                type !== "checkbox" &&
                type !== "textarea" && (
                    <input
                        {...props}
                        type={type}
                        className={
                            "border-gray-medium   text-sm focus:border-primary focus:ring-primary rounded-md  min-h-[50px] pb-0 peer placeholder:text-transparent " +
                            inputClassName +
                            inputClassNameExtra +
                            inputBackground
                        }
                        ref={localRef}
                        placeholder="name"
                        onChange={props.onChange}
                    />
                )}
            {type === "textarea" && (
                <textarea
                    {...props}
                    type={type}
                    className={
                        "border-gray-medium   text-sm focus:border-primary focus:ring-primary rounded-md  min-h-[150px] pb-0 peer placeholder:text-transparent pt-5" +
                        inputClassName +
                        inputClassNameExtra +
                        inputBackground
                    }
                    ref={localRef}
                    placeholder="name"
                    onChange={props.onChange}
                />
            )}
            {type === "select" && (
                <select
                    {...props}
                    className={
                        "border-gray-medium text-sm focus:border-primary focus:ring-primary rounded-md  min-h-[50px] pb-0 peer placeholder:text-transparent " +
                        inputClassName +
                        inputClassNameExtra +
                        inputBackground
                    }
                    ref={localRef}
                    onChange={props.onChange}
                >
                    {props.children}
                </select>
            )}

            {type !== "checkbox" && (
                <label
                    htmlFor={props.name}
                    className={classNames(
                        "absolute left-0 ml-2 translate-y-1 text-primary bg-transparent px-1 text-xs duration-100 ease-linear peer-placeholder-shown:translate-y-3.5 peer-placeholder-shown:text-sm peer-placeholder-shown:text-black  peer-focus:translate-y-1  peer-focus:text-xs peer-focus:text-primary"
                    )}
                >
                    {labelContent}
                    {props.required && "*"}
                </label>
            )}

            {type === "checkbox" && (
                <SwitchInput
                    onChange={props.onChange}
                    enabled={props.value}
                    label={labelContent || ""}
                    discription={descriptionContent}
                />
            )}
        </div>
    );
});
