import { useState } from "react";
import { Field, Label, Switch } from "@headlessui/react";

interface SwitchInputProps {
    className?: string;
    onChange?: (value: boolean) => void;
    enabled: boolean;
    label: string;
    discription?: string;
}

export default function SwitchInput({
    className = "",
    onChange,
    label,
    discription,
    enabled,
    ...props
}: SwitchInputProps): JSX.Element {
    return (
        <Field className={`flex justify-items-center`}>
            <Switch
                checked={enabled}
                defaultChecked={enabled}
                onChange={(e) => {
                    onChange && onChange(e);
                }}
                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-checked:bg-primary mb-2"
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-checked:translate-x-5  "
                />
            </Switch>
            <div className="">
                {label && (
                    <Label
                        className={`ml-2 text-sm ${
                            discription && "font-semibold"
                        }`}
                    >
                        {label}
                    </Label>
                )}
                {discription && (
                    <div className={"ml-2 text-xs mt-1"}>{discription}</div>
                )}
            </div>
        </Field>
    );
}
